.react-daterange-picker__wrapper {
  border: 0.5px solid #c3c3c3 !important;
  border-radius: 5px;
  padding: 8px 6px;
  /* display: flex; */
  align-items: center;
  height: 42px;
  width: 12.25rem;
  overflow: hidden;
}

.date {
  font-size: 0.98rem;
}

/* Hide scrollbars */
.hide-scrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  overflow: -moz-scrollbars-none;
  overflow: -webkit-scrollbar none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

.no-padding {
  padding: 0px !important;
}

.no-margin {
  margin: 0px !important;
}

@layer base {
  img {
    display: initial;
  }
}
