.logoCon {
  width: 12.5rem;
  margin-bottom: 0.75rem;
}

.logoSvg {
  width: 100px;
}

.statement-parent {
  page-break-before: always;
  /* Force a new page for each statement-parent */
  margin: 0;
  /* Remove extra margins that might cause spacing issues */
  padding: 0;
  /* Ensure no additional padding is added */
}

@media print {
  body {
    margin: 0;
    padding: 0;
  }

  .statement-parent {
    page-break-inside: avoid;
    /* Ensure the content doesn't split across pages */
    page-break-after: avoid;
    /* Avoid breaking after the element */
  }
}
